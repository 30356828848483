var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.lesson !== null)?_c('div',[_c('div',{staticClass:"flex flex-wrap justify-between"},[(_vm.user.isAdmin)?_c('a-button',{staticClass:"mr-2",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Regresar ")]):_c('a-button',{staticClass:"mr-2",on:{"click":function($event){return _vm.$router.push({ name: 'lessons.index', query: { coursesection: _vm.lesson.course_section_id }})}}},[_vm._v(" Regresar ")]),_c('a-dropdown',{attrs:{"width":"w-80"},scopedSlots:_vm._u([{key:"menu",fn:function(){return [_c('a-dropdown-item',{on:{"click":function($event){return _vm.handleEvaluated()}}},[_vm._v(" "+_vm._s(_vm.lesson.evaluated_at ? 'esta clase ya ha sido evaluada' : 'EVALUAR esta clase')+" ")]),_c('a-dropdown-item',{on:{"click":_vm.setCeroToAll}},[_c('div',{staticClass:"text-left"},[_vm._v(" Asignar CERO a toda la seccion para la fecha ")])])]},proxy:true}],null,false,2072092167)})],1),_c('div',{staticClass:"py-4 flex justify-between flex-wrap"},[(_vm.lesson.subject)?_c('h2',{staticClass:"text-2xl"},[_vm._v("Tema: "+_vm._s(_vm.lesson.subject))]):_c('h2',{staticClass:"text-2xl"},[_vm._v("Tema: - sin asignar -")]),_c('div',[(_vm.lesson.evaluated)?_c('h4',{staticClass:"text-xl"},[_vm._v("Fecha: "+_vm._s(_vm._f("date")(_vm.lesson.date)))]):_vm._e(),(_vm.lesson.registered_at)?_c('h4',{staticClass:"text-xl"},[_vm._v("Fecha de registro: "+_vm._s(_vm._f("date")(_vm.lesson.registered_at)))]):_c('h4',{staticClass:"text-xl"},[_vm._v("Fecha de registro: No se ha registrado esta clase")])])]),_c('a-table',{attrs:{"skeleton-count":5,"loading":_vm.loadingLesson,"columns":_vm.columns,"source":_vm.lesson.students},scopedSlots:_vm._u([{key:"td(name)",fn:function(ref){
var item = ref.item;
return [_c('p',{class:['text-black', {
            'text-red-400': _vm.includeItem(item)
          }]},[_vm._v(_vm._s(((item.last_name) + " " + (item.first_name))))])]}},{key:"td(note)",fn:function(ref){
          var item = ref.item;
return [(_vm.lesson.evaluated)?_c('a-input',{staticClass:"w-fit mb-2 flex-1",attrs:{"id":("student_note_" + (item.id)),"type":"number","max":20,"placeholder":"nota","disabled":item.failed_by_delay,"error":_vm.error((item + ".note"), { attribute: 'note' })},model:{value:(item.note),callback:function ($$v) {_vm.$set(item, "note", $$v)},expression:"item.note"}}):_vm._e()]}},{key:"td(attendance)",fn:function(ref){
          var item = ref.item;
return [_c('a-switch',{attrs:{"disabled":item.note > 0 && !_vm.user.isAdmin},scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_vm._v(" Marcar inasistencia ")]},proxy:true}],null,true),model:{value:(item.attendance),callback:function ($$v) {_vm.$set(item, "attendance", $$v)},expression:"item.attendance"}})]}},{key:"td(delayed)",fn:function(ref){
          var item = ref.item;
return [_c('a-switch',{scopedSlots:_vm._u([{key:"suffix",fn:function(){return [_vm._v(" Marcar retraso ")]},proxy:true}],null,true),model:{value:(item.delayed),callback:function ($$v) {_vm.$set(item, "delayed", $$v)},expression:"item.delayed"}})]}}],null,false,698466701)}),_c('div',{staticClass:"flex justify-end py-4"},[_c('a-button',{on:{"click":function($event){return _vm.handleBulk({ lesson_id: _vm.lesson.id, students: _vm.lesson.students })}}},[_vm._v(" Confirmar ")])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }